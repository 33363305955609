@tailwind base;
@tailwind components;
@tailwind utilities;

.layout {
	@apply px-4 sm:px-16 w-full mx-auto;
}

.fonts-h2 {
	@apply leading-10 text-gray-700 font-bold;
	font-size: 2rem;
}

.fonts-body {
	@apply text-gray-50 leading-6 font-normal;
}
